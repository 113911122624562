var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"950px","persistent":"","scrollable":"","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.PAGE_TEMPLATE"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":_vm.$t('FORMS.name'),"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.name']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.name'] =
                    ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].name),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "name", $$v)},expression:"formModel.translations[selectedLocale.lang].name"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Slug","rules":_vm.requiredRules,"id":_vm.dynamicID,"error-messages":_vm.messages['slug']},on:{"keyup":function($event){_vm.messages['slug'] = ''}},model:{value:(_vm.formModel.slug),callback:function ($$v) {_vm.$set(_vm.formModel, "slug", $$v)},expression:"formModel.slug"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-textarea',{attrs:{"rows":"15","label":"JSON","rules":_vm.requiredRules,"id":_vm.dynamicID,"error-messages":_vm.messages['fields']},on:{"keyup":function($event){_vm.messages['fields'] = ''}},model:{value:(_vm.formModel.fields),callback:function ($$v) {_vm.$set(_vm.formModel, "fields", $$v)},expression:"formModel.fields"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }